import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GetData } from '../../../services/GetData';

const StatusGuias = () => {

    const [EnBodega, setEnBodega] = useState(0)
    const [EnRuta, setEnRuta] = useState(0)
    const [EnNovedadCli, setEnNovedadCli] = useState(0)
    const [EnReexped, setEnReexped] = useState(0)
    const [Entregadas, setEntregadas] = useState(0)
    const [EnDevolucion, setEnDevolucion] = useState(0)
    const [Loading, setLoading] = useState(0)

    const navigate = useNavigate();

    useEffect(() => {
      setLoading(true)
      GetData('guias/listadoguiasestados/').then ((result) =>{
        if (result.error === false){
            
            const estadosguia = result.estados;
            estadosguia.forEach(estado => {
                switch(estado.id_estado_guia){
                    case "1": 
                          setEnBodega(estado.cant);
                      break;
                    case "2": 
                          setEnRuta(estado.cant);
                      break;
                    case "4": 
                          setEnNovedadCli(estado.cant);
                      break;
                    case "5": 
                          setEnReexped(estado.cant);
                      break;
                    case "3": 
                          setEntregadas(estado.cant);
                      break;
                    case "7": 
                          setEnDevolucion(estado.cant);
                      break;
                    default:
  
                      break;       
                }
            });
            setLoading(false)
        }
     });

     
    }, [])
    
    
   const IrA = (id) => {
         navigate('/home/consultarguias/'+id, { replace: true })
         return 0;
   }
   const IrA2 = (id) => {
         navigate('/home/consultarguiasentre/'+id, { replace: true })
         return 0;
   }

  return (
    <>
            <div className="row justify-content-center">
             
              <div className="col-sm-6 col-xl-4 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex border-bottom mb-4 pb-2 mt-3">
                      <div className="hexagon cursor" onClick={()=>IrA(1)}>
                        <div className="hex-mid hexagon-warning">
                          <i className="mdi mdi-home-variant"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-warning mb-0"> {EnBodega} </h4>
                        <h6 className="text-muted">En Bodega</h6>
                        { Loading  &&
                            <LinearProgress />
                        }  
                      </div>
                    </div>
                    <div className="d-flex border-bottom mb-4 pb-2">
                      <div className="hexagon cursor" onClick={()=>IrA(2)}>
                        <div className="hex-mid hexagon-danger">
                          <i className="mdi mdi-truck"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-danger mb-0">{EnRuta}</h4>
                        <h6 className="text-muted">En Ruta</h6>
                        { Loading  &&
                            <LinearProgress />
                        }
                      </div>
                    </div>
      
                    <div className="d-flex border-bottom mb-4 pb-2">
                      <div className="hexagon cursor" onClick={()=>IrA(4)}>
                        <div className="hex-mid hexagon-info">
                          <i className="mdi mdi-clock-outline"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-info mb-0">{EnNovedadCli}</h4>
                        <h6 className="text-muted">En Novedad Cliente</h6>
                        { Loading  &&
                            <LinearProgress />
                        }
                      </div>
                    </div>
                    <div className="d-flex border-bottom mb-4 pb-2">
                      <div className="hexagon cursor" onClick={()=>IrA(5)}>
                        <div className="hex-mid hexagon-primary">
                          <i className="mdi mdi-timer-sand"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-primary mb-0"> {EnReexped} </h4>
                        <h6 className="text-muted mb-0">En Reexpedición</h6>
                        { Loading  &&
                            <LinearProgress />
                        }
                      </div>
                    </div>
                    <div className="d-flex border-bottom mb-4 pb-2">
                      <div className="hexagon cursor" onClick={()=>IrA2(3)}>
                        <div className="hex-mid hexagon-success">
                          <i className="mdi mdi-check-decagram"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-primary mb-0"> {Entregadas} </h4>
                        <h6 className="text-muted mb-0">Efectivas</h6>
                        { Loading  &&
                            <LinearProgress />
                        }
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="hexagon cursor" onClick={()=>IrA2(7)}>
                        <div className="hex-mid hexagon-dark">
                          <i className="mdi mdi-undo"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-primary mb-0"> {EnDevolucion} </h4>
                        <h6 className="text-muted mb-0">Canceladas</h6>
                        { Loading  &&
                            <LinearProgress />
                        }
                      </div>
                    </div>


                  </div>
                </div>
              </div>
           
            </div>
    </>
  )
}

export default StatusGuias