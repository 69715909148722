import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Usuario } from '../../../services/Usuario';
import { useLocation } from 'react-router-dom';


const Sidebar = ({handleOpenNuevaGuia}) => {

  
    const numeral = "#";    
    const navigate = useNavigate();
    const {	id_tipos_usuario } = Usuario();
    const [buscar, setBuscar] = useState("") 
    const [NumDespacho, setNumDespacho] = useState("") 
    const [MenuActivo, setMenuActivo] = useState("")
    const usuario = Usuario()

    const location = useLocation();

    const getMenuItems = () => 
    {        
        const menuItems = [
          { //0
            title: 'Inicio',
            icon: 'mdi mdi-home menu-icon',
            route: '/home',
            indice: 0
          },
          { //1
            title: 'Configuración',
            icon: 'mdi mdi-sign-caution menu-icon',
            children: [
              {
                title: 'Tipos Usuarios',
                route: '/home/tiposusuario',
                icon: 'mdi mdi-account-key icono_item',
                children: true,
                indice: 1
              },
              {
                title: 'Usuarios',
                route: '/home/usuarios',
                icon: 'mdi mdi-account-multiple icono_item',
                children: true,
                indice: 2
              },
              {
                title: 'Vehículos',
                route: '/home/vehiculos',
                icon: 'mdi mdi-car icono_item',
                children: true,
                indice: 3
              },
              {
                title: 'Empleados',
                route: '/home/empleados',
                icon: 'mdi mdi-clipboard-account icono_item ',
                children: true,
                indice: 4
              },
              {
                title: 'Empresas',
                route: '/home/empresas',
                icon: 'mdi mdi-human icono_item',
                children: true,
                indice: 5
              }
            ]
          }, //1
          {
            title: 'Consultar guías',
            icon: 'mdi mdi-file-find menu-icon',
            route: '/home/consultarguias',
            indice: 6
          }, //2
          {
            title: 'Colección',
            icon: 'mdi mdi-briefcase-check menu-icon',
            route: '/home/coleccion',
            indice: 7
          }, //3
          {
            title: 'Rutas hoy',
            icon: 'mdi mdi-truck-delivery menu-icon',
            route: '/home/rutashoy',
            indice: 8
          }, //4
          {
            title: 'Nuevo Despacho',
            icon: 'mdi mdi-note-plus-outline',
            route: '/home/nuevodespachos',
            indice: 9
          }, //5
          {
            title: 'Despachos',
            icon: 'mdi mdi-package-variant menu-icon',
            route: '/home/listadodespachos',
            indice: 10
          }, // 7
          {
            title: 'Importar Guias',
            icon: 'mdi mdi-file-import menu-icon',
            route: '/home/importarguias',
            indice: 11
          } 
        ];



       const accesousuario = usuario.acceso.split(',').map(index => parseInt(index.trim()));

       return filterMenuItems(menuItems, accesousuario);
        
  }
  
  function filterMenuItems(menuItems, accessIndices) {
    return menuItems.map(item => {
      if (accessIndices.includes(item.indice)) {
        return item; // Conserva el elemento principal si es accesible
      } else if (item.children && item.children.length > 0) {
        // Filtra los hijos que son accesibles
        const accessibleChildren = item.children.filter(child => accessIndices.includes(child.indice));
        if (accessibleChildren.length > 0) {
          // Si al menos un hijo es accesible, asigna los hijos filtrados al elemento
          return {
            ...item, // Mantiene las propiedades del elemento principal
            children: accessibleChildren, // Reemplaza los hijos con los accesibles
          };
        }
      }
      return null; // Devuelve null para excluir elementos no accesibles
    }).filter(Boolean); // Filtra elementos null (no accesibles) del resultado final
  }
    
          
    useEffect(() => {
        let pagina = location.pathname;
        let partes = pagina.split("/")      
        if (partes.length <= 3)
            setMenuActivo(pagina)       
        else{
            pagina = "/"  + partes[1] + "/" + partes[2];
            setMenuActivo(pagina)       
        }    
        
      
    }, [location])

  /*   const cambiandoMenu = (ruta) => {
        localStorage.setItem('rutaActual', ruta.toString());        
    } */
    

    const _handleKeyDown = (e) => {
    //  e.preventDefault();
      if (e.key === 'Enter') {          
          navigate('/home/detalleguia/'+buscar, { replace: true })
      }
      return 0;
    }
    const BuscarDespacho = (e) => {
    //  e.preventDefault();
      if (e.key === 'Enter') {          
          navigate('/home/verdepachos/'+NumDespacho, { replace: true })
      }
      return 0;
    }

    const renderSubMenu = (subMenu) => {
      return (
        <ul>
          {subMenu.map((item, index) => {
            return (
              <li className="nav-item" key={item.route}>
                <NavLink activeclassname="nav-link"  className="nav-link"  to={item.route} key={`submenu_${index}`} > 
                         <i className={item.icono}></i>{item.title}</NavLink>
                </li>            
            );
          })}
        </ul>
      );
    };
    
    
    const renderMenuItems = (items) => {
      return items.map((item, index) => {
        if (item.children && item.children.length > 0) {
          return (
            <li className={`nav-item ${MenuActivo === item.route ? 'active' : ''}`} key={`menu${index}`}>
              <a className="nav-link" data-toggle="collapse" key={`#link_a_${index}`} href={`#ui-basic${index}`} aria-expanded="false" aria-controls={`ui-basic${index}`}>
                <i className={item.icon}></i>
                <span className="menu-title">{item.title}</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id={`ui-basic${index}`}>
                <ul className="nav flex-column sub-menu">
                  {renderMenuItems(item.children)}
                </ul>
              </div>
            </li>
          );
          }else {
          return (
            <li className={`nav-item ${MenuActivo === item.route ? 'active' : ''}`} key={`menu${index}`}>
              <NavLink className="nav-link" to={item.route} key={`menu_ap_${index}`}  >
                <i className={item.icon}></i>
                <span className="menu-title">{item.title}</span>
              </NavLink>
            </li>
          );
        }
      });
    };
    
  const allowedMenuItems = getMenuItems();

  return (
    <>
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="" key="menu0">
            <a className="nav-link d-block" href="index.html">
              <img className="sidebar-brand-logo logoslider" src="/img/logo_unirex_pq.png" alt=""  />
              <div className="font-weight-light pt-1 text-center font-weight-bold">V. 1.3</div>              
            </a>
          </li>
          <li className="nav-item pt-3" key="menu1">
           
            <div className="d-flex align-items-center" >
              <div className="input-group">
                <div className="input-group-prepend">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" className="form-control border-0" 
                      value={buscar} 
                      onChange={event => setBuscar(event.target.value)}  
                      onKeyDown={_handleKeyDown}
                      placeholder="Buscar Guía" />
              </div>
            </div>
            <div className="d-flex align-items-center" >
              <div className="input-group">
                <div className="input-group-prepend">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" className="form-control border-0" 
                      value={NumDespacho} 
                      onChange={event => setNumDespacho(event.target.value)}  
                      onKeyDown={BuscarDespacho}
                      placeholder="Buscar Despacho" />
              </div>
            </div>
          </li>
          <li className="pt-2 pb-1" key="menu2">
            <span className="nav-item-head">Menú</span>
          </li>

          {renderMenuItems(allowedMenuItems)}
       
          
        </ul>
      </nav>
    </>
  )
}

export default Sidebar